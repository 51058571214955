import Grid from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./AsideNews.module.css";
import { CategoryTag } from "../../../MainPage/CategoryTag/CategoryTag";
import useBreakpoint from "../../../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";
import { cleanAndTruncateText } from "../../../../helpers/cleanAndTruncateText";
import { NewsCategory } from "../../../../types/News";

interface AsideNewsProps {
  tag: NewsCategory;
  cover: string;
  title: string;
  description: string;
  date: string;
  alias: string;
  id: number;
  loading: boolean;
}

export const AsideNews = ({
  cover,
  title,
  description,
  date,
  tag,
  alias,
  id,
  loading,
}: AsideNewsProps) => {
  const isSmall = useBreakpoint(1100);
  const navigate = useNavigate();

  const handleHyperlink = () => {
    navigate(`/news/${alias}-${id}`);
  };

  return (
    <Grid container className={styles.container} onClick={handleHyperlink}>
      <Grid
        lg={12}
        md={5}
        xs={4}
        sm={3}
        className={styles.cover}
        sx={{ backgroundImage: `url(${cover})` }}
      >
        <CategoryTag category={tag} size="small" />
      </Grid>
      <Grid lg={12} md={7} xs={8} sm={9} className={styles.infoContainer}>
        <p className={`${styles.infoTitle} ${loading&&'textTransparent'}`}>{title}</p>
        <p className={`${styles.description} ${loading&&'textTransparent'}`}>{cleanAndTruncateText(description, 15)}</p>
        <div className={styles.dateContainer}>
          <p className={`${loading&&'textTransparent'}`}>{date}</p>
          {!isSmall && <p>czytaj dalej...</p>}
        </div>
      </Grid>
    </Grid>
  );
};

//todo: funkcjonalność gdy nie ma nowszego to pokazuje jakiś inny z tej samej kategorii
