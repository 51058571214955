import styles from "./BannerWithSocials.module.css";
import { CategoryTag } from "../../MainPage/CategoryTag/CategoryTag";
import type { NewsCategory } from "../../../types/News";
import { SocialButton } from "../SocialButton/SocialButton";


interface BannerWithSocials {
  cover: string;
  category: NewsCategory;
  linkInstagram?: string;
  linkFacebook?: string;
  linkYoutube?: string;
}


export const BannerWithSocials = ({
  cover,
  category,
  linkInstagram,
  linkFacebook,
  linkYoutube,
}: BannerWithSocials) => {
  return (
    <div className={styles.container}>
      <div className={styles.cover} style={{backgroundImage: `url(${cover})`, backgroundColor: '#ebebeb'}}>
        <CategoryTag category={category} size="big" />
      </div>
      <div className={styles.socialsContainer}>
        {/* <SocialButton platform="instagram" label="Instagram"/>
        <SocialButton platform="facebook" label="Facebook"/>
        <SocialButton platform="youtube" label="Youtube"/> */}
      </div>
    </div>
  );
};
