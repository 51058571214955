// textUtils.ts

export const cleanString = (text: string): string => {
    const cleanedText = text
      .replace(/<br\s*\/?>/gi, "")
      .replace(/<\/?[^>]+(>|$)/g, "");
    return cleanedText.replace(/[\r\n]+/g, " ").trim();
  };
  
  export const cleanAndTruncateText = (text: string, wordLimit: number = 30): string => {
    // Clean the string by removing HTML tags and trimming whitespace
    const cleanedText = cleanString(text);
  
    // Split the cleaned text into words
    const words = cleanedText.split(" ");
  
    // Check if the number of words exceeds the word limit
    if (words.length > wordLimit) {
      // Truncate the words to the specified word limit and add '...'
      return words.slice(0, wordLimit).join(" ") + "...";
    }
  
    // Return the original text if it's within the word limit
    return cleanedText;
  };
  