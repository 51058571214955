import styles from "./NewsGrid.module.css";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { News } from "../News/News";
import { News as TNews } from "../../../../types/newsAPI";
import { useEffect, useState } from "react";
import { MainPageNewsMockupData } from "../../../../constants/MainPageNewsMockupData";
import type { NewsCategory } from "../../../../types/News";

interface NewsGridProps {
  news: TNews[];
  loading: boolean;
}

export const NewsGrid = (props: NewsGridProps) => {
  const [data, setData] = useState<TNews[]>(MainPageNewsMockupData);
  const { news, loading } = props;

  useEffect(() => {
    if (news && news.length > 0) {
      setData(news);
    }
  }, [news]);


  return (
    <Grid container spacing={2} className={styles.container}>
      {data.map((news, index) => {
        return (
          <Grid xs={12} sm={6} lg={4} key={`${news.id}-${index}`}>
            <News
              cover={news.obrazek}
              category={news.kategoria.alias as NewsCategory}
              description={news.tresc}
              date={news.data}
              title={news.temat}
              link={`${news.alias}-${news.id}`}
              loading={loading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
