import styles from "./Footer.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import logo from "../../styles/assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube, faFacebook } from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <div className={styles.footer} id="footer-visibility">
      <Grid container className={styles.container} spacing={2}>
        <Grid className={styles.logoContainer} xs={12} md={12} lg={2} sx={{ order: { xs: 1, lg: 3 } }}>
          <img src={logo} alt="logo" className={styles.logo} />
        </Grid>
        <Grid
          className={styles.socialsContainer}
          xs={12}
          md={6}
          lg={4}
          sx={{ order: { xs: 3, lg: 2 } }}
          container
          spacing={0}
        >
          <Grid xs={12} className={styles.title}>
            <h2>Social media</h2>
          </Grid>
          <Grid xs={12} className={styles.socialsContent}>
            <div className={styles.socialsItem}>
              <a href="https://www.instagram.com/magazyn_otaku/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
                <p className="text-big">Instagram</p>
              </a>
            </div>
            <div className={styles.socialsItem}>
              <a href="https://www.youtube.com/@MagazynOtaku" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} />
                <p className="text-big">Youtube</p>
              </a>
            </div>
            <div className={styles.socialsItem}>
              <a href="https://www.facebook.com/MagazynOtaku/?locale=pl_PL" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} />
                <p className="text-big">Facebook</p>
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={styles.contactContainer}
          xs={12}
          md={6}
          lg={5}
          sx={{ order: { xs: 2, lg: 1 } }}
          container
          spacing={0}
        >
          <Grid xs={12} className={styles.title}>
            <h2>Kontakt</h2>
          </Grid>
          <Grid xs={12} md={6} className={styles.contactAdress}>
            <p className="text-big">Studio JG | Magazyn Otaku</p>
            <p className="text-big">al. Jana Pawła II 72 lok.42</p>
            <p className="text-big">00-175 Warszawa</p>
          </Grid>
          <Grid xs={12} md={6} className={styles.contactPhoneMail}>
            <p className="text-big">
              <FontAwesomeIcon icon={faPhone} /> +48 123 456 789
            </p>
            <a href={"mailto:contact@studiojg.pl"} className="text-big">
              <FontAwesomeIcon icon={faEnvelope} /> contact@studiojg.pl
            </a>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.cookieContainer} id="open-consent-banner"></div>
    </div>
  );
};
