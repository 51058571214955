import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./NewsDetails.module.css";
import { NewsHeader } from "../../components/NewsDetailPage/NewsHeader/NewsHeader";
import { BannerWithSocials } from "../../components/NewsDetailPage/BannerWithSocials/BannerWithSocials";
import { AsideNews } from "../../components/NewsDetailPage/AsideNewsGrid/AsideNews/AsideNews";
import { convertToParagraphs } from "../../helpers/convertToParagraphs";
import { useParams } from "react-router-dom";
import { useFetchNewsDetail } from "../../hooks/useFetchNewsDetails";
import { newsDetailsMockupData } from "../../constants/NewsDetailsMockupData";
import { Banner, NewsItem } from "../../types/NewsDetailsAPI";
import { NewsCategory } from "../../types/News";
import { Helmet } from "react-helmet-async";

const description =
  "Szczegóły wybranego newsa ze świata mangi i anime. Zobacz pełny artykuł, recenzje, opinie i komentarze dotyczące najnowszych wydarzeń.";

const NewsDetails = () => {
  const { id } = useParams();
  const newsId = id?.split("-").pop();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentNews, previousNews, nextNews, error, loading } = useFetchNewsDetail(Number(newsId));

  const [currentNewsData, setCurrentNewsData] = useState<NewsItem>(newsDetailsMockupData);
  const [previousNewsData, setPreviousNewsData] = useState<NewsItem | null>(newsDetailsMockupData);
  const [nextNewsData, setNextNewsData] = useState<NewsItem | null>(newsDetailsMockupData);
  const [promoBanner, setPromoBanner] = useState<Banner>(newsDetailsMockupData.banery[0]);

  useEffect(() => {
    if (currentNews && currentNews.id) {
      setCurrentNewsData(currentNews);
    }
    if (previousNews && previousNews.id) {
      setPreviousNewsData(previousNews);
    } else if (!previousNews) {
      setPreviousNewsData(null);
    }
    if (nextNews && nextNews.id) {
      setNextNewsData(nextNews);
    } else if (!nextNews) {
      setNextNewsData(null);
    }
  }, [currentNews, previousNews, nextNews]);

  useEffect(() => {
    if (currentNews && currentNews.banery && currentNews.banery.length > 0) {
      const { banery } = currentNews;
      setPromoBanner(banery[0]);
    }
  }, [currentNews]);

  return (
    <>
      <Helmet>
        <title>{currentNews ? currentNews.temat : ""} | Magazyn Otaku</title>
        <meta name="description" content={currentNews ? currentNews.temat : ""} />
      </Helmet>
      <main>
        <Grid container spacing={0} className={styles.container}>
          <Grid xs={12} md={6.5} lg={7.5} className={styles.mainContent}>
            <NewsHeader
              title={currentNewsData.temat}
              subtitle={""}
              postedBy={currentNewsData.autor.nick}
              date={currentNewsData.data}
              loading={loading}
            />
            <BannerWithSocials
              cover={currentNewsData.obrazek}
              category={currentNewsData.kategoria.alias as NewsCategory}
            />
            <p className={`text-big ${styles.mainText} ${loading && "textTransparent"}`}>
              {convertToParagraphs(currentNewsData.tresc)}
            </p>
          </Grid>
          <Grid xs={12} md={5} lg={4} className={styles.asideContent}>
            {loading && (
              <>
                <aside>
                  <p className={styles.tag}>Poprzedni news</p>
                  <AsideNews
                    cover={newsDetailsMockupData.obrazek}
                    title={newsDetailsMockupData.temat}
                    description={newsDetailsMockupData.tresc}
                    date={newsDetailsMockupData.data}
                    tag="mockup"
                    alias={newsDetailsMockupData.alias}
                    id={newsDetailsMockupData.id}
                    loading={loading}
                  />
                </aside>
                <aside>baner</aside>
                <aside>
                  <p className={styles.tag}>Poprzedni news</p>
                  <AsideNews
                    cover={newsDetailsMockupData.obrazek}
                    title={newsDetailsMockupData.temat}
                    description={newsDetailsMockupData.tresc}
                    date={newsDetailsMockupData.data}
                    tag="mockup"
                    alias={newsDetailsMockupData.alias}
                    id={newsDetailsMockupData.id}
                    loading={loading}
                  />
                </aside>
              </>
            )}
            {previousNewsData && (
              <aside>
                <p className={styles.tag}>Poprzedni news</p>
                <AsideNews
                  cover={previousNewsData.obrazek}
                  title={previousNewsData.temat}
                  description={previousNewsData.tresc}
                  date={previousNewsData.data}
                  tag={previousNewsData.kategoria.alias as NewsCategory}
                  alias={previousNewsData.alias}
                  id={previousNewsData.id}
                  loading={loading}
                />
              </aside>
            )}
            <div className={styles.promoContainer}>
              <a href={promoBanner.url} className={styles.promoBanner} target="blank">
                <img src={promoBanner.obrazek} alt="banner promocyjny yatta.pl" />
              </a>
              <a href={promoBanner.url} className={styles.promoBanner} target="blank">
                <img src={promoBanner.obrazek} alt="banner promocyjny yatta.pl" />
              </a>
            </div>
            {nextNewsData && (
              <aside>
                <p className={styles.tag}>Kolejny news</p>
                <AsideNews
                  cover={nextNewsData.obrazek}
                  title={nextNewsData.temat}
                  description={nextNewsData.tresc}
                  date={nextNewsData.data}
                  tag={nextNewsData.kategoria.alias as NewsCategory}
                  alias={nextNewsData.alias}
                  id={nextNewsData.id}
                  loading={loading}
                />
              </aside>
            )}
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default NewsDetails;

//todo: opisy do metadata
