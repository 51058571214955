import React, { useEffect } from "react";
import styles from "./Slide.module.css";
import type { NewsCategory } from "../../../../../types/News";
import { CategoryTag } from "../../../CategoryTag/CategoryTag";
import { Link, useNavigate } from "react-router-dom";

interface SlideProps {
  cover: string;
  category: NewsCategory;
  description: string;
  link: string;
  id: number;
  loading: boolean;
}

const truncateText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  }
  return text;
};

export const Slide = ({
  cover,
  category,
  description,
  link,
  id,
  loading,
}: SlideProps) => {
  return (
    <article
      className={styles.slideContainer}
      style={{ backgroundColor: "#ebebeb" }}
      role="button"
      aria-label={`Read more about ${truncateText(description, 30)}`}
    >
      <Link to={`/news/${link}`}>
        <div
          className={styles.coverImage}
          style={{ backgroundImage: `url(${cover})` }}
        >
          <CategoryTag category={category} size="big" />
          <div className={styles.shadow} aria-hidden="true"></div>
        </div>
        <section className={styles.textContainer}>
          <p
            className={`${styles.slideDescription} ${
              loading ? styles.slideDescriptionLoading : ""
            }`}
          >
            {truncateText(description, 150)}
          </p>
        </section>
      </Link>
    </article>
  );
};
