const MAGAZINES_API_URL = "https://otaku.pl/api/?a=magazyny";
const NEWS_API_URL = "https://otaku.pl/api/?a=newsy";
const NEWSDETAILS_API_URL = "https://otaku.pl/api/?a=news";

interface FetchNewsParams {
  strona: number;
  limit: number;
  szukaj: string;
  kategorie: string;
}

interface FetchNewsDetailsParams {
  id: number;
}

export const fetchMagazines = async () => {
  const response = await fetch(MAGAZINES_API_URL);
  if (!response.ok) {
    throw new Error("Failed to fetch magazines");
  }
  return response.json();
};

export const fetchNews = async ({
  strona,
  limit,
  szukaj,
  kategorie,
}: FetchNewsParams) => {
  const response = await fetch(NEWS_API_URL, {
    method: "POST", // Use POST method
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      strona,
      limit,
      szukaj,
      kategorie,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch news");
  }

  return response.json();
};

export const fetchNewsDetails = async (props: FetchNewsDetailsParams) => {
  const { id } = props;

  const response = await fetch(NEWSDETAILS_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to fetch news");
  }

  return response.json();
};
