export const MainPageNewsMockupData = [
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
  {
    id: -1,
    temat: "Najnowszy news ze świata anime",
    alias: 'najnowszy-news-ze-swiata-anime',
    tresc: "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
    data: "2024-08-02 00:00:00",
    obrazek: "/mockup-background.jpg",
    autor: {
      id: 0,
      nick: "Otaku",
    },
    kategoria: {
      id: 0,
      nazwa: "mockup",
      alias: "mockup",
      kolor: "#FFA500",
    },
  },
];
