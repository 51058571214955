import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BannerSlider } from "../Slider/Slider/Slider";
import { News } from "../../../../types/newsAPI";

interface MainPageBannerProps{
  newsy: News[];
  loading: boolean;
}

export const MainPageBanner = (props: MainPageBannerProps) => {
  const {newsy, loading} = props

  return (
    <section aria-label="Main Page News Banner">
      <BannerSlider newsy={newsy} loading={loading}/>
    </section>
  );
};
