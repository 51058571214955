import React, { useRef, useEffect, useState } from "react";
import styles from "./News.module.css";
import type { NewsCategory } from "../../../../types/News";
import { CategoryTag } from "../../CategoryTag/CategoryTag";
import { Link, useNavigate } from "react-router-dom";

const formatDate = (dateString: string) => {
  return dateString.split(" ")[0];
};

// Truncates the text to fit within the container's width
const truncateTextToFit = (text: string, containerWidth: number, fontSize: string, fontFamily: string) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context!.font = `${fontSize} ${fontFamily}`;

  let truncatedText = text;
  while (context!.measureText(truncatedText).width > containerWidth) {
    truncatedText = truncatedText.slice(0, -1);
  }

  return truncatedText.length < text.length ? truncatedText + "..." : text;
};

// Cleans the text by removing HTML tags
const cleanString = (text: string): string => {
  const cleanedText = text.replace(/<br\s*\/?>/gi, "").replace(/<\/?[^>]+(>|$)/g, "");
  return cleanedText.replace(/[\r\n]+/g, " ").trim();
};

interface NewsProps {
  cover: string;
  category: NewsCategory;
  description: string;
  date: string;
  title: string;
  link?: string;
  loading: boolean;
}

export const News = ({ cover, category, description, date, title, link, loading }: NewsProps) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [truncatedDescription, setTruncatedDescription] = useState<string>(cleanString(description));
  
  useEffect(() => {
    const resizeListener = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const fontSize = "14px"; // Adjust to your actual font size
        const fontFamily = "Arial"; // Adjust to your actual font family
        const truncated = truncateTextToFit(cleanString(description), containerWidth, fontSize, fontFamily);
        setTruncatedDescription(truncated);
      }
    };

    resizeListener(); // Initial check
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [description]);

  return (
    <article className={styles.container} ref={containerRef}>
      <Link to={`/news/${link}`} className={styles.newsContainer}>
        <section className={styles.newsCover} style={{ backgroundImage: loading?"":`url(${cover})`, backgroundColor: "#ebebeb" }}>
          <CategoryTag size="small" category={loading?"mockup":category} />
        </section>
        <section className={styles.newsContent}>
          <p className={`text-big ${styles.title} ${loading && styles.textTransparent}`}>{title}</p>
          <p className={`text-big ${loading && styles.textTransparent}`}>{truncatedDescription}</p>
          <p className={`text-small ${styles.date} ${loading && styles.textTransparent}`}>{formatDate(date)}</p>
        </section>
      </Link>
    </article>
  );
};
