import styles from "./Slider.module.css";
//@ts-ignore
import Slider from "react-slick";
import { Slide } from "../Slide/Slide";
import { Kategorie, News } from "../../../../../types/newsAPI";
import { NewsCategory } from "../../../../../types/News";
import { useEffect, useState } from "react";
import { MainPageNewsMockupData } from "../../../../../constants/MainPageNewsMockupData";

interface BannerSliderProps {
  newsy: News[];
  loading: boolean;
}

const cleanString = (text: string): string => {
  const cleanedText = text
    .replace(/<br\s*\/?>/gi, "")
    .replace(/<\/?[^>]+(>|$)/g, "");
  return cleanedText.replace(/[\r\n]+/g, " ").trim();
};

export const BannerSlider = (props: BannerSliderProps) => {
  const [data, setData] = useState<News[]>(MainPageNewsMockupData)
  const { newsy, loading } = props;

  useEffect(() => {
    if (newsy && newsy.length > 0) {
      setData(newsy);
    }
  }, [newsy]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: true,
    accesibility: true,
  };

  return (
    <Slider {...settings} className={styles.sliderContainer}>
      {data.slice(0, 3).map((news, index) => {
        return (
          <Slide
            cover={news.obrazek}
            category={news.kategoria.alias as NewsCategory}
            description={cleanString(news.temat)}
            link={`${news.alias}-${news.id}`}
            key={`${news.id}-${index}`}
            id={news.id}
            loading={loading}
          />
        );
      })}
    </Slider>
  );
};